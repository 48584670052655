<template>
  <div class="container">
    <div class="mt-30">
      <p class="title">CARROT Leadership 지원하기</p>
      <p class="sub-title mt-30 txt-center">
        {{lead.ename}}({{lead.kname}})님, 환영합니다!
      </p>
    </div>
    <div class="mt-30" >
      <span class="contents">1. CARROT Leadership 지원 동기를 기재해주세요.</span>
      <textarea v-model.trim="lead.comment1" class="mt-10 w-100per h-180px" maxlength="500"></textarea>
    </div>
    <div class="mt-30">
      <span class="contents">2. Lead로서의 자신의 비전을 기재해주세요.</span>
      <textarea v-model.trim="lead.comment2" class="mt-10 w-100per h-180px" maxlength="500"></textarea>
    </div>
    <div class="mt-30">
      <span @click="lead.doSubmit" class="btn-default btn-apply ml-175 mr-175 pointer">지원하기</span>
      </div>
  </div>

</template>
<script>
// @ is an alias to /src
import {onMounted, reactive} from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import {useStore} from "vuex";

export default {
  components: {
  },
  setup() {
    const toast  = useToast();
    const store = useStore();
    const lead = reactive({
      uid : "",
      upw : "",
      check:'N',
      list:[],
      listSubLast:[],
      memo1:'',
      kname:'',
      ename:'',
      team_name:'',
      office_name:'',
      sector_name:'',
      joinday:'',
      doSubmit : () => {
        let params = {
          kname : lead.kname,
          ename : lead.ename,
          team_name : lead.team_name,
          office_name : lead.office_name,
          sector_name : lead.sector_name,
          joinday : lead.joinday,
          comment1 : lead.comment1,
          comment2 : lead.comment2,
        };
        axios.get("/rest/personal/sendLeadershipMail",{ params : params } ).then((res) => {
          if( res.data.err == 0 ){
            console.log(res.data);
            toast.error("완료되었습니다.");
            close();
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      initSearch : () => {
        axios.get("/rest/personal/getGNBInfo", { params : {} }).then((res) => {
          if( res.data.err == 0 ){
            lead.kname= res.data.kname;
            lead.ename= res.data.ename;
            lead.team_name= res.data.team_name;
            lead.office_name= res.data.office_name;
            lead.sector_name= res.data.sector_name;
            lead.joinday= res.data.joinday;
          } else {
            console.log(res.data);
          }
        });
      },
    });
    onMounted(() => {
      lead.initSearch();


      console.log(store.state);
    });

    return {lead,store};
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 550px;
  margin: 0 auto;
}
.title{
  font-size:32px;
  color:#fd6011;
  font-weight: bold;
}
.sub-title{
  font-size:20px;
  font-weight:bold;
}
.contents{
  font-size:16px;
  font-weight:bold;
}
.btn-apply{
  padding-top:18px;
  padding-bottom:18px;
  font-size:18px;
  font-weight:bold;
  border-radius: 20px;
  color:#ffffff;
  background-color:#fd6011;
}
</style>